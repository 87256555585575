import Img1 from "../images/work/Snapinsta.app_356242053_595790449333024_1958590348683932896_n_1080.jpg";
import Img2 from "../images/work/Snapinsta.app_385225510_677280500786187_3034810898930875352_n_1080.jpg";
import Img3 from "../images/work/PIZZA.png";
import Img4 from "../images/work/Untitled design (3).png";
import Img5 from "../images/work/Grid Photo Collage Instagram Post (6).png";
import Img6 from "../images/work/saturday (2).png";

const data = [
    {
        id: 1,
        imgSrc: Img1,
        class: "vehicles"
    },
    {
        id: 2,
        imgSrc: Img2,
        class: "valentine"
    },
    {
        id: 3,
        imgSrc: Img3,
        class: "thug-life"
    },
    {
        id: 4,
        imgSrc: Img4,
        class: "party"
    },
    {
        id: 5,
        imgSrc: Img5,
        class: "fitness"
    },
    {
        id: 6,
        imgSrc: Img6,
        class: "chaiPoint1"
    }
]

export default data;